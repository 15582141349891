import React, { Component } from 'react';
import Header from "../component/Header";
import '../assets/style.css';
import '../assets/question/TypeCommon.js';
import axios from 'axios';
// import Jumbotron from 'react-bootstrap/Jumbotron';
// import Toast from 'react-bootstrap/Toast';
// import Container from 'react-bootstrap/Container';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';


class ExamEnd extends Component{
    constructor(props){
        super(props);
        this.state = {
            _cno    :   this.props.match.params.courseno,
            _userno : window.users.userno,
        }

    }
    ClearHistory=()=>{

        let self = this

        const apiUrl = 'https://tickau.engpool.com/cbt/ajax_question_history_clear.cshtml';
        var callback = "callback";
        var CourseNo   = this.state._cno;    
        var UserNo = window.users.userno;     // R:Radet, T:Tower

        
        var optionAxios = {
            headers: {
                //'Content-Type': 'application/x-www-form-urlencoded'
                //'Content-Type': 'multipart/form-data'            
                'Content-Type': 'access-control-request-method' 
                
            }
        }
        let form = new FormData() 
        form.append('cno', CourseNo)
         form.append('uno',UserNo) 
         
         axios.post(apiUrl, form) 
         .then( response => { 
             console.log('response : ', JSON.stringify(response, null, 2)) 
             alert('기록이 초기화 되었습니다.');
             this.testAgain();
            }
        )
         .catch( error => { 
            alert('문항 저장에 실패했습니다.');
            return;
            console.log('failed', error)
         })
       
    }        
    testAgain=()=>{
        // this.props.history.push("/"+this.state._cno);
        this.props.history.push('/test/'+this.state._cno+'/1');
    }

    GoNext=()=>{
        window.opener = null;
        window.open('', '_self');
        window.close();
    }    

    render(){

        document.querySelector('body').style='background-color: rgb(142, 198, 63)';

        return(
            <div>
            <Header />            
            <div className="body" style={{backgroundColor:"#8ec63f"}}>
            <div className="Last_box">
                <img src="../../images/ico_test.png"/>
                <h1>수고하셨습니다.</h1>
                <div style={{marginTop:10}}><span>테스트 결과는 약 2주 이내, [학습리포트] 메뉴에서 확인 가능합니다. </span></div>
                
                <div className="btn_area">
                {/* <button className="btn_retest" id="" onClick={this.ClearHistory}>다시풀기</button>  */}
                <button className="btn_close" id="" onClick={this.GoNext}>종료하기</button> 
                </div>

                </div>
                </div>
                </div>
        )
    }
}
export default ExamEnd;