import React from 'react';
import { Link } from 'react-router-dom';


const UsageProgram = (props) => {
    const webtoon = props.webtoon;
    return (
        <div className="body">
        <div className="navi2">	</div>
        <div className="contents_guide1">         
            <div className="text_box">
                <div className="title">How to use program tool</div>
                <div className="basic_type">
                <div className="pro_info"><img src="../images/program_info.png" width="800"/></div>
                </div>
                <div className="btn_box">
                <ul>
                    <li className="second">Next page in <span className="color_re" id="id_Second">&nbsp;</span> Seconds</li>
                        <li><Link to="/test/2/1"><img src="../images/btn_confirm_normal.png" onload="InitButtion(this, true);"/></Link> </li>
                </ul>
                </div>
            </div>
            
        </div>
    </div>
    )
}
export default UsageProgram;