import React, { Component } from 'react';
import { findAllByDisplayValue, render } from '@testing-library/react';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';
import { useAlert } from "react-alert";

const alert = useAlert;

class ansChoice extends Component {         


    constructor(props) {
        super(props);
        this.state = {
          _ansFlag: false,
          _showFlag:false,
          nextStaus: false
        }
    
      }

    ChoiceAnswer=(_obj)=>{
        let self = this;
    
        this.setState({
            _ansFlag:_obj.currentTarget.innerHTML  
        })
               
        console.log(_obj.currentTarget.innerHTML + '을 선택했습니다.');
        
        this.state.nextStaus=true;
        this.props.enableGoNext();
        
        {
            /*
            this.props.GoNext(); 
            this.state._QType===10?
                this.state._userRecordingFile !="A" && this.state._userRecordingFile !="B" && this.state._userRecordingFile !="C" && this.state._userRecordingFile !="D" ?
                {
                    Alert.show("보기 답을 선택해주세요")
                }          
            */
            
    
        }
    }

    componentWillReceiveProps(nextProps){

        this.setState({
            _ansFlag : false
        });
    }

    showChoiceAns(){
        this.setState({
            _showFlag : true
        });

        
    }

    render(){
        
            //document.querySelector('#id_msg_warning').style.visibility = "visible";

            if(!this.state._showFlag){
                return(
                    <div>loading</div>
                )
            }

            if(this.props.questions.QueType==10){
                return (
    
                    <div className="btn_box">
                    <button className={this.state._ansFlag=='A' ?  "btn_answer on":"btn_answer" } id="btn-answer" onClick={this.ChoiceAnswer} value="A" >A</button> 
                    <button className={this.state._ansFlag=='B' ?  "btn_answer on":"btn_answer" } id="btn-answer" style={{marginLeft:'10px'}} onClick={this.ChoiceAnswer} value="B" >B</button> 
                    <button className={this.state._ansFlag=='C' ?  "btn_answer on":"btn_answer" } id="btn-answer"  style={{marginLeft:'10px'}} onClick={this.ChoiceAnswer} value="C">C</button> 
                    </div>
                    
                    //  <div className="msg_warning">
                    //  <Button id="btn-answer" variant={this.state._ansFlag=='A' ?  'light':'primary' }  size="lg" onClick={this.ChoiceAnswer} value="A">A</Button>
                    //  <Button id="btn-answer" variant={this.state._ansFlag=='B' ?  'light':'primary' }  size="lg" onClick={this.ChoiceAnswer} value="B">B</Button>
                    //  <Button id="btn-answer" variant={this.state._ansFlag=='C' ?  'light':'primary' }  size="lg" onClick={this.ChoiceAnswer} value="C">C</Button>                    
                    //  <img id="id_msg_warning" style={{margin:'0 auto', visibility:''}} alt=""/></div>    
                    //  </div> 
                )
            }
            else{
                return(
                    <div>33</div>
                )
        }   
        /*}
        else{
            return (
                <div></div>     
        
                
            )
        }
        */
    }
  
}

export default ansChoice;