import React, { Component } from 'react';

class NoticeCautionKor extends Component {
    constructor(props){
        super(props);

        this._noticeurl = "https://file2.engpool.com/toeic/epta/testnewepta/kau/common/"

    }
    GoNext=()=>{
        
        this.props.history.push('/ready/7/'+this.props.courseno);
    
    }    
    render(){
        var notice_img = this._noticeurl + "lv_notice1_" + this.props.courseno+".jpg";
        return (
            <div className="body">
            <div className="contents_guide1"> 
            <div className="notice">
                                <img id="id_Next" src={notice_img}/>
                            </div>

                
                <div className="btn_box" style={{marginTop:'20px'}}>
                    <ul>                    
                        <li><img id="id_Next" src="../Images/btn_confirm_normal.png" onload="InitButtion(this, false);" onClick={this.GoNext} style={{ cursor: 'default',margin:'0 auto' }}/> </li>
                    </ul>
                </div>
            </div>
        </div>
        )
    }
}

export default NoticeCautionKor;