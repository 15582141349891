import React from 'react';
import '../component/style.css';
const Header = () => (

  <div className="header_test"> 
    <span>KAU Flight Training Center</span>
    <div class="user">
      <span id="id_UserBirth" className="birth">&nbsp;</span><span className="bar" id="id_Bar">&nbsp;</span><span id="id_UserName" className="name">&nbsp;</span>
    </div>
  </div>

)

export default Header;